import { defineStore } from 'pinia'
import { DEBUG, REDIRECT_LOGOUT_URL, REDIRECT_ON_LOGOUT } from '~/env.ts'
import type { AuthResponse } from '@/types/api/auth'

interface AuthRootState {
  loggedIn: boolean
  user: {
    id: number
    first_name: string
    surname: string
    email: string
  }

  token: string
  recentSession: number
}

export const useAuthStore = defineStore({
  id: 'authstore',

  state: (): AuthRootState => ({
    loggedIn: false,
    user: {
      id: 0,
      first_name: '',
      surname: '',
      email: '',
    },
    token: '',

    recentSession: 0,
  }),

  actions: {
    init(AuthData: AuthResponse) {
      const session = useCookie('session')
      this.user = AuthData.auth.user
      this.token = AuthData.auth.token

      this.recentSession = AuthData.auth.recentSession ?? 0

      session.value = AuthData.auth.token
      this.loggedIn = true
    },

    logout(redirect = true) {
      const session = useCookie('session')
      const sessionID = useCookie('session_id')
      session.value = null
      sessionID.value = null

      if (DEBUG) {
        if (redirect && REDIRECT_ON_LOGOUT)
          window.location.href = REDIRECT_LOGOUT_URL
        else
          console.log('Debug Logout: no redirect')
      }
      else {
        if (redirect && REDIRECT_ON_LOGOUT) {
          console.error('Logging out: redirecting to REDIRECT_LOGOUT_URL')
          window.location.href = REDIRECT_LOGOUT_URL
        }

        else { console.log('Logout: no redirect') }
      }

      // alert('Logging out - Redirecting')
    },

  },

  getters: {

  },
})
