import { defineStore } from 'pinia'

import { useAuthStore } from '@/stores/auth'

import { KIO } from '@/utility/utility'

import type { UserRecord } from '@/types/api/user'

interface UserRecordRootState {
  initialised: boolean
  record: UserRecord
}

export const useUserRecordStore = defineStore({
  id: 'userrecord',

  state: (): UserRecordRootState => ({
    initialised: false,
    record: {
      sessionsTotal: 0,
      sessionsComplete: 0,
      sessionsIncomplete: 0,
      traitsTotal: 0,
      traitsComplete: 0,
      traitsIncomplete: 0,
      entriesA: 0,
      entriesB: 0,
      entriesTotal: 0,
      entriesPerSession: 0,
      timeTotal: 0,
      timeATotal: 0,
      timeBTotal: 0,
      timePerSession: 0,
      percentageComplete: 0,
      percentageCompleteA: 0,
      percentageCompleteB: 0,
    },
  }),

  actions: {
    updateInitialised(initValue: boolean) {
      this.initialised = initValue
    },

    initUserRecord(record?: UserRecord) {
      return new Promise<void>(async (resolve, reject) => {
        if (record && KIO(record, ['sessionsTotal'])) {
          this.record = record
          this.initialised = true
          resolve()
        }
        else {
          const { $api, $SE } = useNuxtApp()
          const $auth = useAuthStore()
          await $api.user.initUserRecord($auth.user.id).then((record: UserRecord) => {
            this.record = record
            this.initialised = true
            resolve()
          }).catch((error: any) => {
            $SE(error, `$api.user.initUserRecord record - ${error.message}`)
            reject(error)
          })
        }
      })
    },
  },

  getters: {
    getInitialised: state => state.initialised,
  },
})
